import * as React from "react";
import '../../assets/css/auth.css';
import logo from '../../assets/image/logo-ncmu.png';

import {Button, Card, Container, Form, Image} from "react-bootstrap";
import {IconText} from "../../components/icon_txt";
import {loginSubmit} from "../../actions/auth";
import {Check, Input} from "../../components/form/forms";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {LoginSchema} from "./constant";

const Login = () => {
    const loc = useLocation();
    const navigate = useNavigate();

    const formik = useFormik({
        validationSchema: LoginSchema,
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            handleSubmit()
        },
        enableReinitialize: true
    })

    const handleSubmit = () => {
        if (formik.isValid) {
            let href = loc?.state?.from ? loc?.state?.from : '/';
            loginSubmit(formik.values.email, formik.values.password, href).then();
        }
    }

    return (
        <React.Fragment>
            <Container className={'wrapper'}>
                <Link className={'logo-link'} to={'/'}>
                    <Image fluid src={logo}/>
                </Link>
                <Card className={'login-card'}>
                    <Card.Header>Вход в учетную систему ИС «НЦМУ»</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Input label={'Логин'} className="mb-3"
                                   id={'email'}
                                   name={'email'}
                                   value={formik.values.email}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.email}
                                   error={formik.errors?.email}
                            />
                            <Input label={'Пароль'} className="mb-3" type={'password'}
                                   name={'password'}
                                   value={formik.values.password}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.password}
                                   error={formik.errors?.password}
                            />
                            <Check label={'Запомнить меня'} id={'remember'}/>
                            <Button onClick={formik.submitForm} variant={'outline-primary'}>
                                <IconText text={'Войти'} icon={'sign-in-alt'}/>
                            </Button>
                            <Link to={'/forget'} className={'ml-3'}>
                                Забыли пароль?
                            </Link>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    );
};

export default Login;