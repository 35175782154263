import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {useEffect, useRef, useState} from "react";
import {getRequest, putRequest, updateRequest} from "../../../actions/common";
import {
    REQUIRED_FIELDS,
    SEMI_REQUIRED_FIELDS,
    STATUS_ACCEPT,
    STATUS_APPLICATION_APPROVE,
    STATUS_APPLICATION_NEW,
    STATUS_NEW, STATUS_PROCESS,
} from "./constant";
import {useAbac} from "react-abac";
import {PERMISSIONS} from "../../../rbac/constant";
import {Area, Input} from "../../../components/form/forms";
import {SPECIALIST_PARTICIPATION, SPECIALIST_TYPES} from "./constant";
import {MyFileBrowser} from "../../../components/file_manager/file_manager";
import {btnMessage, findInSelectData} from "../../../utils/utils";
import {Field, FormikProvider, useFormik} from "formik";
import {REQUIRED_FIELDS_TEMP} from "./constant";
import {FormikDate, FormikSelect} from "../../../components/form/formik";
import {toast} from "react-toastify";

export const SpecialistItem = ({
                                   data,
                                   setData,
                                   user,
                                   disabled,
                                   messages,
                                   generateOptions,
                                   MessageHistory,
                                   ActionButtons,
                                   DisableButton,
                                   HeaderStatus,
                                   handleSubmit,
                                   ErrorsBlock
                               }) => {
    const params = useParams();
    const navigate = useNavigate();
    const project_ref = useRef();

    const {userHasPermissions} = useAbac();
    const [select_data, setSelectData] = useState({});

    const select_fields = ['country', 'project', 'event', 'organization', 'order'];

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'last_name': '',
        'first_name': '',
        'middle_name': '',
        'birth_date': null,
        'phone': '',
        'snils': '',
        'edu_doc_number': '',
        'edu_doc_date': null,
        'enrollment_order_number': '',
        'enrollment_order_date': '',
        'related_docs_url': '',
        'comment': '',
        'participation_form': null,
        'message': ''
    });

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: values => {
            return saveSpecialist();
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveSpecialist);
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Специалисты | ИС «НЦМУ»`;
            let set_selected = {};
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            let set_inputs = {};
            for (let field in input_fields) {
                if (Object.keys(data).includes(field)) {
                    set_inputs[field] = data[field]
                } else {
                    set_inputs[field] = formik.values?.[field]
                }
            }
            formik.setValues({...set_inputs, ...set_selected, current_status: data.status});
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        getRequest(field, setSelectData, {type_data: `${field !== 'order' ? 'all' : 'only_study'}`}, '', select_data, field);
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            is_educational: true,
                        }, '', select_data, 'event').then(r => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({...formik.values, event_id: null})
                            }
                        });
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values]);

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            // formik.setFieldValue('event_id', null);
            setSelectData({...select_data, event: []})
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id;
    }, [formik.values])

    const saveSpecialist = () => {
        formik.setFieldValue('save_form', false);

        let payload = null;
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = {...formik.values, direction: user.id}
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = {...formik.values, direction_edu: user.id}
        } else {
            payload = formik.values;
        }

        updateRequest('specialist', {'common': payload}, params.id).then(response => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/specialist');
                setData(response.data);
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message);
                if (response.data?.fields)
                    formik.setErrors({...formik.errors, ...response.data?.fields})
            }
        })
        if (formik?.values?.message) {
            putRequest('specialist_messages', {
                content: formik?.values?.message,
                specialist_status: data.status,
                status: data.status,
                created_by: user.id,
                specialist: data.id
            }, false).then(r => {
            })
            document.getElementById('message').value = null;
            formik.setFieldValue('message', null);
        }

        return Promise.resolve('is_saved');
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className="section-header sticky-top">
                        <h1 className="page-header">
                            {`Редактирование записи №${params.id} в БД Специалисты`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons
                            form={formik}
                            permission_control={PERMISSIONS.CONTROL_SPECIALIST}
                            permission_manage={PERMISSIONS.MANAGE_SPECIALIST}
                            can_back={
                                (userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                    || userHasPermissions(PERMISSIONS.EDU_DIRECTION_PANEL))
                                && data?.status === STATUS_APPLICATION_APPROVE && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                    <div
                        className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                        <HeaderStatus style={{width: "180px"}}/>
                    </div>
                    <Button letiant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? {display: "block"} : {display: "none"}}
                          id={'card-message'}>
                        <Card.Body>
                            <div className="alert alert-danger"
                                 style={{display: formik.errors?.message ? "block" : "none"}}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id="field-mention" className="content-group navbar-nav px-0">
                                <div id="field-comment">
                                    <label htmlFor="specialist_message">Комментарий</label>
                                    <Area id="message"
                                          name={"message"}
                                          disabled={disabled}
                                          value={formik.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          rows="5"
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className="text-danger">красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className="text-success">зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className="badge badge-success">Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Персональные данные</legend>
                                <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                                    <Col>
                                        <Input label={'Фамилия'} id={'last_name'}
                                               name={'last_name'}
                                               handleChangeValue={formik.handleChange}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('last_name') : false}
                                               disabled={disabled}
                                               error={formik.errors['last_name']}
                                               invalid={formik.errors['last_name']}
                                               value={formik.values ? formik.values.last_name : null}/>
                                    </Col>
                                    <Col>
                                        <Input label={'Имя'} id={'first_name'}
                                               name={'first_name'}
                                               handleChangeValue={formik.handleChange}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('first_name') : false}
                                               disabled={disabled}
                                               error={formik.errors['first_name']}
                                               invalid={formik.errors['first_name']}
                                               value={formik.values ? formik.values.first_name : null}/>
                                    </Col>
                                    <Col>
                                        <Input label={'Отчество'} id={'middle_name'}
                                               name={'middle_name'}
                                               disabled={disabled}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.middle_name : null}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'birth_date'}
                                               handleChangeValue={formik.handleChange} label={'Дата рождения'}
                                               id={'birth_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('birth_date') : false}
                                               disabled={disabled}
                                               error={formik.errors['birth_date']}
                                               invalid={formik.errors['birth_date']}
                                               value={formik.values ? formik.values.birth_date : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Возраст'} id={'age'} disabled={true}
                                               value={data ? data.age : null}/>
                                    </Col>
                                </Row>
                                <Field component={FormikSelect} name={'country_id'} label={'Гражданство'}
                                       id={'country_id'}
                                       isSearchable={true}
                                       options={generateOptions('country', 'title_short', select_data)}
                                       placeholder={formik.values ? formik.values?.country?.title : ''}
                                       disabled={disabled}
                                       error={formik.errors['country_id']}
                                       invalid={formik.errors['country_id']}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('country_id') : false}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер телефона'}
                                               id={'phone'}
                                               name={'phone'}
                                               required_on_transition={data ? SEMI_REQUIRED_FIELDS[data.status + 1].includes('phone') : false}
                                               mask={'+7(999)999-99-99'}
                                               disabled={disabled}
                                               error={formik.errors['phone']}
                                               invalid={formik.errors['phone']}
                                               small={'Поле является обязательным если указано иностранное гражданство'}
                                               value={formik.values ? formik.values.phone : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'СНИЛС'} id={'snils'}
                                               name={'snils'}
                                               handleChangeValue={formik.handleChange}
                                               required_on_transition={data ? SEMI_REQUIRED_FIELDS[data.status + 1].includes('snils') : false}
                                               mask={'999-999-999 99'}
                                               disabled={disabled}
                                               error={formik.errors['snils']}
                                               invalid={formik.errors['snils']}
                                               small={'Поле является обязательным если указано гражданство РФ'}
                                               value={formik.values ? formik.values.snils : null}/>
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>Специальные данные</legend>
                                <Field component={FormikSelect} name={'type'} label={'Тип (форма подготовки)'}
                                       id={'type'}
                                       isSearchable={true}
                                       options={SPECIALIST_TYPES}
                                       placeholder={data ? SPECIALIST_TYPES.find(e => e.value === data.type)?.label : null}
                                       disabled={(data?.status === STATUS_APPLICATION_NEW
                                           || data?.status === STATUS_NEW)
                                       && userHasPermissions(PERMISSIONS.MANAGER_PANEL) ? true : disabled}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'project_id'}
                                               label={'Проект'} id={'project_id'}
                                               isSearchable={true}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project_id') : false}
                                               handleChangeValue={formik.handleChange}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               disabled={user?.roles.every((value) => value === 'project_manager') ? true : disabled}
                                               error={formik.errors['project_id']}
                                               invalid={formik.errors['project_id']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Направление'} id={'direction'}
                                               name={'direction'}
                                               disabled={true}
                                               value={data ? data?.project?.direction : null}/>
                                    </Col>
                                </Row>
                                <Field component={FormikSelect} name={'event_id'}
                                       label={'Наименование программы'} id={'event_id'}
                                       isSearchable={true}
                                       options={generateOptions('event', (element) => {
                                           if (element?.title_short)
                                               return `(${element.title_short}) ${element.title}`
                                           else
                                               return `${element.title}`
                                       }, select_data)}
                                       disabled={disabled}
                                       error={formik.errors['event_id']}
                                       invalid={formik.errors['event_id']}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('event_id') : false}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'organization_id'}
                                               label={'Наименование организации'} id={'organization_id'}
                                               isSearchable={true}
                                               handleChangeValue={formik.handleChange}
                                               options={generateOptions('organization', 'title_short', select_data)}
                                               disabled={disabled}
                                               error={formik.errors['organization_id']}
                                               invalid={formik.errors['organization_id']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('organization_id') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'participation_form'}
                                               label={'Форма участия штатных сотрудников НЦМУ'}
                                               id={'participation_form'}
                                               isSearchable={true}
                                               options={SPECIALIST_PARTICIPATION}
                                               disabled={data ?
                                                   userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? disabled : true
                                                   : true}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <fieldset>
                                <legend>Подтверждающие документы</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер документа об образовании'}
                                               id={'edu_doc_number'}
                                               name={'edu_doc_number'}
                                               value={formik.values ? formik.values.edu_doc_number : null}
                                               disabled={disabled}
                                               error={formik.errors['edu_doc_number']}
                                               invalid={formik.errors['edu_doc_number']}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('edu_doc_number') : false}
                                               small={'Обязательное поле для формы подготовки «Обучающийся». Документ об образовании, полученный в рамках НЦМУ'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'edu_doc_date'}
                                               label={'Дата документа об образовании'} id={'edu_doc_date'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.edu_doc_date : null}
                                               disabled={disabled}
                                               error={formik.errors['edu_doc_date']}
                                               invalid={formik.errors['edu_doc_date']}
                                               small={'Документ об образовании, полученный в рамках НЦМУ'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('edu_doc_date') : false}
                                        />
                                    </Col>
                                </Row>
                                <Field component={FormikSelect} name={'order_id'}
                                       label={'Приказ'} id={'order_id'}
                                       isSearchable={true}
                                       options={generateOptions('order', 'title', select_data)}
                                       disabled={disabled}
                                       error={formik.errors['order_id']}
                                       invalid={formik.errors['order_id']}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('order_id') : false}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер приказа о зачислении / включении в рабочую группу'}
                                               id={'enrollment_order_number'}
                                               name={'enrollment_order_number'}
                                               value={formik.values ? formik.values.enrollment_order_number : null}
                                               disabled={true}
                                               small={'Номер приказа о зачислении - для формы подготовки «Обучающийся», номер приказа о включении в рабочую группу - для формы подготовки «Молодой исследователь»'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'enrollment_order_date'}
                                               label={'Дата приказа о зачислении / включении в рабочую группу'}
                                               id={'enrollment_order_date'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.enrollment_order_date : null}
                                               disabled={true}
                                               small={'Дата приказа о зачислении - для формы подготовки «Обучающийся», дата приказа о включении в рабочую группу - для типа «Молодой исследователь»'}
                                        />
                                    </Col>
                                </Row>
                                <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       handleChangeValue={formik.handleChange}
                                       small={'Скан документа, подтверждающий поле «Вид документа»'}
                                       disabled={disabled}
                                       value={formik.values ? formik.values.related_docs_url : null}/>
                                <hr/>
                                <Area label={'Комментарий (дополнительная информация)'} id={'comment'}
                                      name={'comment'}
                                      handleChangeValue={formik.handleChange}
                                      disabled={disabled}
                                      value={formik.values ? formik.values.comment : null}/>
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                <MyFileBrowser path={`root/storage/specialist/specialist_${params.id}`}
                                               instanceId={`specialist_${params.id}`}
                                               read_only={disabled}
                                />
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}
