import * as React from "react";
import {IconText} from "../components/icon_txt";

import {Button, Card, ListGroup, ListGroupItem} from "react-bootstrap";
import {logoutSubmit} from "../actions/auth";
import {useContext} from "react";
import {AuthContext} from "../auth";
import {PERMISSIONS, ROLES_LABELS} from "../rbac/constant";
import {Link} from "react-router-dom";
import {useAbac} from "react-abac";

const Home = () => {
    React.useEffect(() => {
        document.title = 'Главная | ИС «НЦМУ»';
    });

    const user = useContext(AuthContext);
    const {userHasPermissions} = useAbac();

    const logoutHandle = (e) => {
        e.preventDefault();
        logoutSubmit();
    }

    const MenuButton = ({href_url, icon, title}) => {
        return (
            <React.Fragment>
                <ListGroupItem>
                    <Link to={href_url} style={{width: '100%', height: '100%', display: 'block', color: 'inherit'}}>
                        <IconText text={title} icon={icon}/>
                    </Link>
                </ListGroupItem>
            </React.Fragment>
        )
    }

    const CustomListGroup = ({lead, children, col_size}) => {
        return (
            <React.Fragment>
                <div className={`col-lg-${col_size}`}>
                    {lead ? <p className="lead">{lead}</p> : <React.Fragment/>}
                    <ListGroup className="list-group">
                        {children}
                    </ListGroup>
                </div>
            </React.Fragment>
        )
    }

    const Roles = () => {
        if (user) {
            let elements = [];
            user.roles.forEach((role) => {
                elements.push(<span key={role}
                                    className={`badge ${role === 'admin' ? 'badge-danger' : 'badge-secondary'}`}> {ROLES_LABELS[role]}</span>);
            });
            return elements;
        }
        return null;
    }

    return (
        <React.Fragment>
            <h1 className={"page-header"}>
                Главная
            </h1>
            <div className={"card-deck mb-4"}>
                <Card bg={"light"}>
                    <Card.Body>
                        <div className={"row"}>
                            <CustomListGroup lead={"Мониторинг"} col_size={6}>
                                <MenuButton href_url={"/indicator"} icon={'thermometer-half'}
                                            title={"Показатели"}/>
                                <MenuButton href_url={"/indicator-direction"} icon={'map-signs'}
                                            title={" Показатели по направлениям"}/>
                                <MenuButton href_url={"/indicator-project"} icon={'project-diagram'}
                                            title={"Показатели по проектам"}/>
                                <MenuButton href_url={"/indicator/report"} icon={'file-alt'}
                                            title={"Отчеты"}/>
                                <MenuButton href_url={"/indicator/report_fin"} icon={'file-alt'}
                                            title={"Отчеты софинансирования"}/>
                            </CustomListGroup>
                            <CustomListGroup lead={"Дашборд"} col_size={6}>
                                <MenuButton href_url={"/dashboard"} icon={'chart-line'}
                                            title={"Открыть"}/>
                                <MenuButton href_url={"/dashboard-report"} icon={'file-alt'}
                                            title={"Данные"}/>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
                <Card bg={"light"}>
                    <Card.Body>
                        <div className={"row"}>
                            <CustomListGroup lead={"Базы данных"} col_size={12}>
                                <MenuButton href_url={"/staff"} icon={'user-friends'}
                                            title={"Кадры"}/>
                                <MenuButton href_url={"/specialist"} icon={'user-graduate'}
                                            title={"Специалисты"}/>
                                <MenuButton href_url={"/publication"} icon={'book'}
                                            title={"Публикации"}/>
                                <MenuButton href_url={"/rid"} icon={'lightbulb'}
                                            title={"РИДы"}/>
                                <MenuButton href_url={"/financing"} icon={'coins'}
                                            title={"Cофинансирование"}/>
                                {userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ?
                                    <MenuButton href_url={"/amortization"} icon={'money-bill'}
                                                title={"Амортизация"}/> : null}
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>

            </div>
            <div className={"card-deck mb-4"}>
                <Card bg={"light"}>
                    <Card.Body>
                        <p className="lead">Справочники</p>
                        <div className={"row"}>
                            <CustomListGroup lead={""} col_size={6}>
                                <MenuButton href_url={"/dicts/self-academic-degree"} icon={"award"}
                                            title={"Ученые степени"}/>
                                <MenuButton href_url={"/dicts/self-academic-rank"} icon={"graduation-cap"}
                                            title={"Ученые звания"}/>
                                <MenuButton href_url={"/dicts/direction"} icon={"map-signs"}
                                            title={"Направления"}/>
                                <MenuButton href_url={"/dicts/project"} icon={"project-diagram"}
                                            title={"Проекты"}/>
                                <MenuButton href_url={"/dicts/unit"} icon={"ruler-horizontal"}
                                            title={"Единицы измерения"}/>
                                <MenuButton href_url={"/dicts/staff-category"} icon={"user-tag"}
                                            title={"Категории работников"}/>
                                <MenuButton href_url={"/dicts/job-contract"} icon={"file-contract"}
                                            title={"Формы привлечения работников"}/>
                                <MenuButton href_url={"/dicts/event-category"} icon={"calendar-alt"}
                                            title={"Категории мероприятий"}/>
                                <MenuButton href_url={"/dicts/event"} icon={"calendar-alt"}
                                            title={"Мероприятия"}/>
                                <MenuButton href_url={"/dicts/organization"} icon={"building"}
                                            title={"Организации"}/>
                                <MenuButton href_url={"/dicts/patent-office"} icon={"shield-alt"}
                                            title={"Патентные ведомства"}/>
                            </CustomListGroup>
                            <CustomListGroup lead={""} col_size={6}>
                                {user?.roles.every(value => value !== 'project_manager') ?
                                    <MenuButton href_url={"/dicts/person"} icon={"user-friends"}
                                                title={"Персоналии"}/>
                                    : null
                                }
                                <MenuButton href_url={"/dicts/department"} icon={"sitemap"}
                                            title={"Подразделения"}/>
                                <MenuButton href_url={"/dicts/academic-degree"} icon={"award"}
                                            title={"Ученые степени (внешний источник)"}/>
                                <MenuButton href_url={"/dicts/academic-rank"} icon={"graduation-cap"}
                                            title={"Ученые звания (внешний источник)"}/>
                                <MenuButton href_url={"/dicts/country"} icon={"globe"}
                                            title={"Страны"}/>
                                <MenuButton href_url={"/dicts/staff-position"} icon={"street-view"}
                                            title={"Должности"}/>
                                <MenuButton href_url={"/dicts/staff-unit"} icon={"user-shield"}
                                            title={"Штатные единицы"}/>
                                <MenuButton href_url={"/dicts/order"} icon={"book"}
                                            title={"Приказы"}/>
                                <MenuButton href_url={"/dicts/template"} icon={"book"}
                                            title={"Шаблоны"}/>
                                <MenuButton href_url={"/dicts/grant"} icon={"medal"}
                                            title={"Гранты"}/>
                                <MenuButton href_url={"/dicts/publication-type"} icon={"book"}
                                            title={"Вид издания"}/>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
                <Card bg={"light"}>
                    <Card.Body>
                        <div className={"row"}>
                            <CustomListGroup lead={'Профиль'} col_size={12}>
                                <ListGroupItem className={"list-unstyled"}>
                                    <li>
                                        <strong>ФИО</strong>: {user ? user.last_name : null} {user ? user.first_name : null} {user ? user.middle_name : null}
                                    </li>
                                    <li><strong>Email адрес</strong>: {user ? user.email : null}</li>
                                    <li><strong>Роли</strong>: <Roles/></li>
                                    <li>
                                        <strong>Проект</strong>: {user?.project?.title_short ? user?.project?.title_short : 'Не задано'}
                                    </li>
                                    <li className="button-actions mb-0 mt-2">
                                        <Link to={'/forget'}>
                                            <Button variant="outline-secondary">
                                                <IconText text={'Смена пароля'} icon={'key'}/>
                                            </Button>
                                        </Link>
                                        <Button variant="outline-danger" onClick={logoutHandle}>
                                            <IconText text={'Выход'} icon={'sign-out-alt'}/>
                                        </Button>
                                    </li>
                                </ListGroupItem>
                            </CustomListGroup>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default Home;
