import axios from 'axios';
import {toast} from "react-toastify";

export const fullExit = () => {
    localStorage.clear();
    window.location.href = '/login/';
}

const axiosInstance = axios.create({
    timeout: 30000,
    headers: {
        'Authorization': localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.defaults.paramsSerializer = {
  indexes: null,
}

axiosInstance.interceptors.response.use((response) => {
    let message = response?.data?.detail;
    message ? toast.error(message) : null;
    return response;
})

const axiosRefreshToken = async (token) => {
    return await axiosInstance.post('/api/token/refresh/', {
        refresh: token
    })
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data.access);
                axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                return Promise.resolve();
            }
            return Promise.reject();
        }).catch((e) => {
            if (e.response.status === 401) {
                fullExit();
                return Promise.resolve();
            }
            return Promise.reject();

        });
}

export const protectedAxios = async (method, url, options, head) => {
    let refreshToken = null;
    let accessToken = null;

    if (localStorage.getItem('token')) {
        accessToken = localStorage.getItem('token');
        refreshToken = localStorage.getItem('refresh_token');
    } else {
        fullExit();
    }
    if (refreshToken && accessToken) {
        const tokenParts = JSON.parse(atob(accessToken.split('.')[1]));
        const now = Math.ceil(Date.now() / 1000);
        if (tokenParts.exp <= now) {
            await axiosRefreshToken(refreshToken);
        }
    } else {
        fullExit();
    }
    return await method(url, options, head);
}

export default axiosInstance;
