import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import ReactBreadcrumb from "../../../components/breadcrumbs";
import {Button, Card, Col, Row, Form, Badge, Alert} from "react-bootstrap";
import {IconText} from "../../../components/icon_txt";
import {useEffect, useRef, useState} from "react";
import {getRequest, putRequest, updateRequest} from "../../../actions/common";
import {
    STATUS_NEW,
    FINANCING_DOCUMENT_TYPES,
    CAN_MANAGE_ROLES,
    STATUS_CHECK_ACTS,
    STATUS_ACTS,
    STATUS_ACCEPT, REQUIRED_FIELDS_TEMP,
} from "./constant";
import {Area, Check, Input} from "../../../components/form/forms";
import {PERMISSIONS} from "../../../rbac/constant";
import {useAbac} from "react-abac";
import {btnMessage, findInSelectData} from "../../../utils/utils";
import {REQUIRED_FIELDS} from "./constant";
import {MyFileBrowser} from "../../../components/file_manager/file_manager";
import {Field, FieldArray, FormikProvider, useFormik} from "formik";
import {FormikDate, FormikSelect} from "../../../components/form/formik";

const fact_value = {
    assignments: '',
    salary: '',
    salary_sum: 0,
    other: '',
    other_sum: 0,
    full_sum: 0,
}


export const FinancingItem = ({
                                  data,
                                  user,
                                  disabled,
                                  messages,
                                  generateOptions,
                                  ErrorsBlock,
                                  MessageHistory,
                                  ActionButtons,
                                  DisableButton,
                                  HeaderStatus,
                                  setDisabled,
                                  handleSubmit,
                                  setData,
                                  handleSaveAndMove,
                              }) => {
    const params = useParams();
    const navigate = useNavigate();

    const {userHasPermissions} = useAbac();
    const [select_data, setSelectData] = useState({});
    const [count_stages, setCountStages] = useState(1);

    const project_ref = useRef();

    const select_fields = ['project', 'event', 'person'];
    const given_select_fields = ['contract_type'];

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'customer': undefined,
        'contract_number': '',
        'contract_date': undefined,
        'work_date_start': undefined,
        'work_date_end': undefined,
        'account': null,
        'contract_subject': '',
        'total_cost': '',
        'salary': '',
        'salary_sum': '',
        'other': '',
        'other_sum': '',
        'full_sum': '',
        'actual_income': null,
        'without_stages': false,
        'contract_stages': [{
            cost: 0,
            date_start: undefined,
            date_end: undefined,
            is_checked: false
        }],
        'fact_values': [{...fact_value}, {...fact_value}, {...fact_value}, {...fact_value}],
        'is_notes': true,
        'is_contract': true,
    });

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: values => {
            return saveFunction()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveFunction);
    }, [formik.values])

    const setIsNotes = (value) => {
        formik.setFieldValue('is_notes', value);
    }

    const setIsContract = (value) => {
        formik.setFieldValue('is_contract', value);
    }

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Софинансирование | ИС «НЦМУ»`;
            let set_selected = {};
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id;
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field];
            })
            let set_inputs = {};
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_inputs?.contract_stages) {
                set_inputs['contract_stages'] = [{
                    cost: 0,
                    date_start: undefined,
                    date_end: undefined,
                    is_checked: false
                }]
            }
            if (!set_inputs?.fact_values) {
                set_inputs['fact_values'] = [{...fact_value}, {...fact_value}, {...fact_value}, {...fact_value}];
            }

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
                is_notes: formik?.values?.is_notes,
                is_contract: formik?.values?.is_contract
            });

            if (data.contract_stages) {
                setCountStages(data.contract_stages?.length);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        field === 'person' ?
                            getRequest('person_select', setSelectData, {}, '', select_data, field)
                            :
                            getRequest(field, setSelectData, {type_data: 'all'}, '', select_data, field);
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id
                        }, '', select_data, 'event').then(r => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({...formik.values, event_id: null})
                            }
                        });
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values]);

    const AlertDiv = () => {
        let to_return = <Alert variant={'warning'}>
            Для дальнейшего согласования необходимо прикрепить договор и служебную записку внизу страницы
        </Alert>

        return data && data?.status === STATUS_NEW ? to_return : null
    }

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({...select_data, event: []})
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id;
    }, [formik.values])

    const additionalButtons = () => {
        let to_return = [];
        if (data?.status === STATUS_CHECK_ACTS && userHasPermissions(PERMISSIONS.DIRECTION_PANEL)) {
            to_return.push(<Button key={'save_plus_reject'} variant={'warning'} onClick={() => {
                formik.setFieldValue('without_message', true);
                handleSaveAndMove(-1, formik);
            }}>
                <IconText icon={'redo-alt'}
                          text={`Запрос актов`}/>
            </Button>)
        }
        return to_return;
    }

    const saveFunction = () => {
        formik.setFieldValue('save_form', false);

        let payload = null;
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = {...formik.values, direction_by: user.id}
        } else {
            payload = formik.values;
        }

        updateRequest('financing', {'common': payload}, params.id).then(response => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/financing');
                setData(response.data);
            }
        })
        if (formik?.values?.message) {
            putRequest('financing_messages', {
                content: formik?.values?.message,
                financing_status: data.status,
                status: data.status,
                created_by: user.id,
                financing: data.id
            }, false).then()
            document.getElementById('message').value = null;
            formik.setFieldValue('message', '');
        }

        return Promise.resolve('is_saved');
    }
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className="section-header sticky-top">
                        <h1 className="page-header">
                            {`Редактирование записи №${params.id} в БД Софинансирование`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_FINANCING}
                                       permission_manage={PERMISSIONS.MANAGE_FINANCING}
                                       additional_buttons={additionalButtons()}
                                       can_back={data?.status > STATUS_NEW && data?.status !== STATUS_ACCEPT
                                           && data?.status !== STATUS_CHECK_ACTS
                                           && data?.status !== STATUS_ACTS}
                        />
                    </div>
                    <div
                        className="mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center">
                        <HeaderStatus style={{width: "200px"}}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? {display: "block"} : {display: "none"}}
                          id={'card-message'}>
                        <Card.Body>
                            <div className="alert alert-danger"
                                 style={{display: formik.errors?.message ? "block" : "none"}}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id="field-mention" className="content-group navbar-nav px-0">
                                <div id="field-comment">
                                    <label htmlFor="message">Комментарий</label>
                                    <Area id="message"
                                          name={"message"}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows="5"
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className="text-danger">красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className="text-success">зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className="badge badge-success">Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className="badge badge-success">Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Данные договора</legend>
                                <AlertDiv/>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                               disabled={user?.roles.every((value) => value === 'project_manager') ? true : disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Направление'}
                                               id={'direction'}
                                               value={data ? data?.project?.direction : null}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'event_id'}
                                               label={'Мероприятие'}
                                               id={'event_id'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('event')}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('event', (element) => {
                                                   if (element?.title_short)
                                                       return `(${element.title_short}) ${element.title}`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Категория'}
                                               value={data?.event?.category}
                                               disabled={true}
                                               small={'Выставляется автоматически'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'person_id'}
                                               label={'Исполнитель'}
                                               id={'person_id'}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('person') : false}
                                               isClearable={true}
                                               options={generateOptions('person', 'fio', select_data)}
                                               error={formik.errors['person_id']}
                                               invalid={formik.errors['person_id']}
                                               isSearchable={true}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Заказчик (контрагент)'}
                                               id={'customer'}
                                               name={'customer'}
                                               error={formik.errors.customer}
                                               value={formik.values.customer}
                                               invalid={formik.errors.customer}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('customer')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер договора'}
                                               id={'contract_number'}
                                               name={'contract_number'}
                                               error={formik.errors['contract_number']}
                                               invalid={formik.errors['contract_number']}
                                               disabled={disabled}
                                               value={formik.values.contract_number}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_number')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'contract_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата договора'}
                                               id={'contract_date'}
                                               error={formik.errors['contract_date']}
                                               disabled={disabled}
                                               invalid={formik.errors['contract_date']}
                                               value={formik.values.contract_date}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_date')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'work_date_start'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата начала работ'}
                                               id={'work_date_start'}
                                               error={formik.errors['work_date_start']}
                                               invalid={formik.errors['work_date_start']}
                                               disabled={disabled}
                                               value={formik.values.work_date_start}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('work_date_start')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'work_date_end'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания работ'}
                                               id={'work_date_end'}
                                               error={formik.errors['work_date_end']}
                                               invalid={formik.errors['work_date_end']}
                                               disabled={disabled}
                                               value={formik.values.work_date_end}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('work_date_end')}
                                        />
                                    </Col>
                                </Row>
                                <Input handleChangeValue={formik.handleChange}
                                       label={'Лицевой счёт'}
                                       id={'account'}
                                       name={'account'}
                                       error={formik.errors['account']}
                                       invalid={formik.errors['account']}
                                       disabled={disabled}
                                       value={formik.values.account}
                                       required={REQUIRED_FIELDS[STATUS_NEW].includes('account')}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'contract_type'}
                                               label={'Вид договора'}
                                               isClearable={true}
                                               options={FINANCING_DOCUMENT_TYPES}
                                               id={'contract_type'}
                                               error={formik.errors['contract_type']}
                                               invalid={formik.errors['contract_type']}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_type')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Предмет договора'}
                                               id={'contract_subject'}
                                               name={'contract_subject'}
                                               error={formik.errors['contract_subject']}
                                               invalid={formik.errors['contract_subject']}
                                               disabled={disabled}
                                               value={formik.values.contract_subject}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_subject')}
                                        />
                                    </Col>
                                </Row>
                                <Input label={'Общая сумма договора'}
                                       type={'number'}
                                       step={'any'}
                                       name={'total_cost'}
                                       min={0}
                                       required={true}
                                       handleChangeValue={formik.handleChange}
                                       error={formik.errors['total_cost']}
                                       invalid={formik.errors['total_cost']}
                                       id={'total_cost'}
                                       value={formik.values.total_cost}
                                       disabled={disabled}/>
                            </fieldset>
                            <fieldset>
                                <legend>Этапы договора</legend>
                                <FieldArray name={'contract_stages'}
                                            render={arrayHelpers => (<div>
                                                <Check label={'Без этапов'}
                                                       name={'without_stages'}
                                                       id={'without_stages'}
                                                       value={formik.values.without_stages}
                                                       disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && data?.status > STATUS_NEW || disabled}
                                                       handleChangeValue={(event) => {
                                                           if (event.target.checked) {
                                                               setCountStages(1);
                                                               for (let index = count_stages; index > 1; index--) {
                                                                   arrayHelpers.pop()
                                                               }
                                                           }
                                                           formik.handleChange(event);
                                                       }}
                                                />
                                                {
                                                    !disabled ?
                                                        !formik.values.without_stages && !disabled ? <React.Fragment>
                                                                <div className={'mb-4'}>
                                                                    <Button variant={'success'} onClick={() => {
                                                                        setCountStages(count_stages + 1);
                                                                        arrayHelpers.push({
                                                                            cost: 0,
                                                                            date_start: undefined,
                                                                            date_end: undefined,
                                                                            is_checked: false
                                                                        })
                                                                    }}>Добавить этап</Button>
                                                                    <Button variant={'danger'} onClick={() => {
                                                                        if (count_stages > 1) {
                                                                            setCountStages(count_stages - 1);
                                                                            arrayHelpers.pop();
                                                                        }
                                                                    }} className={'ml-2'}>Удалить этап</Button>
                                                                </div>
                                                            </React.Fragment>
                                                            : null : null
                                                }
                                                {arrayHelpers.form.values?.contract_stages?.map((stage, index) => (
                                                    <div key={`stage_card_${index}`}>
                                                        <p>Этап {index + 1}</p>
                                                        <Input handleChangeValue={formik.handleChange}
                                                               name={`contract_stages.${index}.cost`}
                                                               label={'Сумма'}
                                                               type={'number'}
                                                               step={'any'}
                                                               id={`contract_stages.${index}.cost`}
                                                               min={0}
                                                               disabled={
                                                                   (!userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                                       && !userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL))
                                                                   && (stage?.is_checked)
                                                                       ? true : disabled
                                                               }
                                                               value={stage.cost}
                                                               required={true}
                                                               error={arrayHelpers.form.errors?.contract_stages?.[index]?.cost}
                                                               invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.cost}
                                                        />
                                                        <Row>
                                                            <Col>
                                                                <Field component={FormikDate}
                                                                       name={`contract_stages.${index}.date_start`}
                                                                       handleChangeValue={formik.handleChange}
                                                                       label={'Дата начала'}
                                                                       id={'date_start'}
                                                                       disabled={
                                                                           (!userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                                               && !userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL))
                                                                           && (stage?.is_checked)
                                                                               ? true : disabled
                                                                       }
                                                                       value={stage.date_start}
                                                                       required={true}
                                                                       error={arrayHelpers.form.errors?.contract_stages?.[index]?.date_start}
                                                                       invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.date_start}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Field component={FormikDate}
                                                                       name={`contract_stages.${index}.date_end`}
                                                                       handleChangeValue={formik.handleChange}
                                                                       label={'Дата окончания'}
                                                                       id={'date_end'}
                                                                       disabled={
                                                                           (!userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                                               && !userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL))
                                                                           && (stage?.is_checked)
                                                                               ? true : disabled
                                                                       }
                                                                       value={stage.date_end}
                                                                       required={true}
                                                                       error={arrayHelpers.form.errors?.contract_stages?.[index]?.date_end}
                                                                       invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.date_end}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <hr/>
                                                        <label>Акт и платежные поручения (при наличии)</label>
                                                        {stage?.is_checked ?
                                                            <Badge bg={'success'}
                                                                   className={'ml-2'}
                                                                   style={{color: 'white'}}>
                                                                Согласован
                                                            </Badge>
                                                            : null}
                                                        <div>
                                                            {user.roles.some((role) => CAN_MANAGE_ROLES[STATUS_CHECK_ACTS].includes(role)) ?
                                                                <Check
                                                                    label={'Проверено'}
                                                                    handleChangeValue={formik.handleChange}
                                                                    disabled={
                                                                        userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                                        && (data?.status > STATUS_NEW || stage?.is_checked)
                                                                            ? true : disabled
                                                                    }
                                                                    name={`contract_stages.${index}.is_checked`}
                                                                    id={`contract_stages.${index}.is_checked`}
                                                                    error={arrayHelpers.form.errors?.contract_stages?.[index]?.is_checked}
                                                                    invalid={arrayHelpers.form.errors?.contract_stages?.[index]?.is_checked}
                                                                    value={stage?.is_checked}
                                                                />
                                                                : null}
                                                        </div>
                                                        <Input label={'№ акта'}
                                                               handleChangeValue={formik.handleChange}
                                                               value={stage.act_number}
                                                               id={`contract_stages.${index}.act_number`}
                                                               name={`contract_stages.${index}.act_number`}
                                                               required={data ? REQUIRED_FIELDS[data.status].includes('act_number') : false}
                                                               small={'Если акт без номера введите «б/н»'}
                                                               disabled={
                                                                   userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                                   && data?.status === STATUS_ACTS
                                                                   && (stage?.is_checked)
                                                                       ? false
                                                                       : disabled
                                                               }
                                                               error={formik.errors?.contract_stages?.[index]?.act_number}
                                                               invalid={formik.errors?.contract_stages?.[index]?.act_number}
                                                        />
                                                        <Field component={FormikDate}
                                                               handleChangeValue={formik.handleChange}
                                                               label={'Дата акта'}
                                                               id={`contract_stages.${index}.date_act`}
                                                               name={`contract_stages.${index}.date_act`}
                                                               disabled={
                                                                   userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                                   && data?.status === STATUS_ACTS
                                                                   && (stage?.is_checked)
                                                                       ? false
                                                                       : disabled
                                                               }
                                                               value={stage.date_act}
                                                               required={data ? REQUIRED_FIELDS[data.status].includes('date_act') : false}
                                                               error={formik.errors?.contract_stages?.[index]?.date_act}
                                                               invalid={formik.errors?.contract_stages?.[index]?.date_act}
                                                        />
                                                        <Input label={'Фактический приход денежных средств по акту'}
                                                               type={'number'}
                                                               step={'any'}
                                                               handleChangeValue={formik.handleChange}
                                                               value={stage.actual_income}
                                                               id={`contract_stages.${index}.actual_income`}
                                                               name={`contract_stages.${index}.actual_income`}
                                                               required={data ? REQUIRED_FIELDS[data.status].includes('actual_income') : false}
                                                               disabled={
                                                                   userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                                   && data?.status === STATUS_ACTS
                                                                   && (stage?.is_checked)
                                                                       ? false
                                                                       : disabled
                                                               }
                                                               error={formik.errors?.contract_stages?.[index]?.actual_income}
                                                               invalid={formik.errors?.contract_stages?.[index]?.actual_income}
                                                        />
                                                        <MyFileBrowser
                                                            path={`root/storage/financing/financing_${params.id}/stage_${index}`}
                                                            instanceId={`stage_${index}`}
                                                            read_only={stage?.is_checked ? true : data?.status === STATUS_ACTS
                                                            && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? false
                                                                :
                                                                !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                                && data?.status === STATUS_NEW ? false : disabled}
                                                            height={250}
                                                        />
                                                        <hr/>
                                                    </div>
                                                ))}
                                            </div>)}
                                />
                            </fieldset>
                            <fieldset>
                                <legend>Фактические значения</legend>
                                <FieldArray name={'fact_values'} render={arrayHelpers => {
                                    return arrayHelpers.form.values?.fact_values?.map((fact, quarter) => (
                                        <div key={`fact_value_${quarter}`}>
                                            <legend>Квартал №{quarter + 1}</legend>
                                            <Area label={'Платежные поручения'}
                                                  id={`fact_values.${quarter}.assignments`}
                                                  name={`fact_values.${quarter}.assignments`}
                                                  handleChangeValue={formik.handleChange}
                                                  value={fact.assignments}
                                                  disabled={
                                                      userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                      || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL)
                                                          ? disabled : true
                                                  }
                                            />
                                            <Row>
                                                <Col>
                                                    <Area label={'Зарплата'}
                                                          id={`fact_values.${quarter}.salary`}
                                                          name={`fact_values.${quarter}.salary`}
                                                          handleChangeValue={formik.handleChange}
                                                          value={fact.salary}
                                                          disabled={userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                          || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ? disabled : true}
                                                    />
                                                    <Input type={'number'}
                                                           label={'Сумма зарплаты'}
                                                           handleChangeValue={formik.handleChange}
                                                           value={fact.salary_sum}
                                                           id={`fact_values.${quarter}.salary_sum`}
                                                           name={`fact_values.${quarter}.salary_sum`}
                                                           disabled={userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                           || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ? disabled : true}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Area label={'Другое'}
                                                          id={`fact_values.${quarter}.other`}
                                                          name={`fact_values.${quarter}.other`}
                                                          handleChangeValue={formik.handleChange}
                                                          value={fact.other}
                                                          disabled={userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                          || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ? disabled : true}
                                                    />
                                                    <Input type={'number'}
                                                           label={'Сумма "Другое"'}
                                                           handleChangeValue={formik.handleChange}
                                                           value={fact.other_sum}
                                                           id={`fact_values.${quarter}.other_sum`}
                                                           name={`fact_values.${quarter}.other_sum`}
                                                           disabled={userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                           || userHasPermissions(PERMISSIONS.FINANCING_DIRECTION_PANEL) ? disabled : true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Input label={'Общая сумма'}
                                                   type={'number'}
                                                   id={`fact_values.${quarter}.full_sum`}
                                                   name={`fact_values.${quarter}.full_sum`}
                                                   value={fact.full_sum}
                                                   disabled={true}/>
                                        </div>))
                                }}/>
                            </fieldset>
                            <fieldset>
                                <hr/>
                                <Row>
                                    <Col md={4}>
                                        <Input type={'number'}
                                               label={'Сумма «Зарплата»'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values.salary_sum}
                                               id={'salary_sum'}
                                               name={'salaty_sum'}
                                               disabled={true}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type={'number'}
                                               label={'Сумма «Другое»'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values.other_sum}
                                               id={'other_sum'}
                                               name={'other_sum'}
                                               disabled={true}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Input type={'number'}
                                               label={'Сумма «Общая сумма»'}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values.full_sum}
                                               id={'full_sum'}
                                               name={'full_sum'}
                                               disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Input type={'number'}
                                       label={'Сумма «Фактический приход денежных средств по акту»'}
                                       handleChangeValue={formik.handleChange}
                                       disabled={true}
                                       value={formik.values.actual_income}
                                       id={'actual_income'}
                                       name={'actual_income'}
                                />
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <Row>
                                    <Col md={6}>
                                        <div className={'form-group required'}>
                                            <Form.Label>Файл договора</Form.Label>
                                            <MyFileBrowser
                                                path={`root/storage/financing/financing_contract_${params.id}`}
                                                read_only={data?.status > STATUS_NEW && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                    ? true : disabled}
                                                instanceId={`financing_contract_${params.id}`}
                                                setIsEmpty={setIsContract}
                                                height={250}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className={'form-group required'}>
                                            <Form.Label>Файл служебной записки</Form.Label>
                                            <MyFileBrowser
                                                path={`root/storage/financing/financing_notes_${params.id}`}
                                                read_only={data?.status > STATUS_NEW && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                    ? true : disabled}
                                                instanceId={`financing_notes_${params.id}`}
                                                setIsEmpty={setIsNotes}
                                                height={250}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                                <MyFileBrowser path={`root/storage/financing/financing_${params.id}`}
                                               read_only={data?.status > STATUS_NEW && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                                   ? true : disabled}
                                               instanceId={`financing_${params.id}`}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
        ;
}
