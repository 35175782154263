import React, {Fragment, useContext, useEffect} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {AuthContext} from "../../auth";

const RouteGuard = ({component: Component, user, title}) => {
    const is_loading = typeof window === 'undefined';

    return <Fragment>
        {is_loading && !user || !localStorage.getItem('token')
            ? <Navigate to={'/login'}/>
            : <Component user={user} title={title}/>}
    </Fragment>
};

export default RouteGuard;